/**
 * Este repositorio ofrece una API de abstracción particular para manipular
 * secciones.
 * En un futuro, podrían no ser precargadas desde el servidor; podrian ser cargadas
 * de manera asincronica.
 * De realizar ese cambio, los clientes del Repositorio, no se enteran del cambio.
 */

import Repository from "./Repository";

const resource = '/orders';

export default {
	// Las traeria del servidor en un futuro ...
	create(payload) {
		return Repository.post(`${resource}`, payload);
	},
	list() {
		return Repository.get(resource);
	},
	get(id) {
		var endpoint = resource.concat('/:id').replace(':id', id);
		return Repository.get(endpoint);
	},
}