<template>
	<layouts-minimum :buttonBack="{ description: 'al listado', path: '/order' }">
		<div v-if="!order">
			<div class="d-flex justify-content-between mt-2">
				<Skeletor class="badge rounded-pill" width="80" />
				<div class="text-sm fw-bold">
					<Skeletor width="50" />
					<Skeletor width="50" class="ms-7" />
				</div>
			</div>

			<div class="list-group list-group-flush mt-5">
				<div class="list-group-item d-flex align-items-center px-0 py-1 border-0" v-for="i in 5" v-bind:key="i">
					<div>
						<Skeletor class="d-block h6 fw-regular mb-0" width="70" />
					</div>
					<div class="ms-auto text-end">
						<Skeletor class="fw-bold" width="150" />
					</div>
				</div>
			</div>

			<hr />

			<div class="mt-5">
				<Skeletor class="ls-tight fw-bolder h4" width="200" />
			</div>

			<div class="list-group list-group-flush">
				<div class="list-group-item px-0">
					<div class="d-flex">
						<div class="pe-2 h5"><Skeletor width="20" /></div>
						<div class="flex-fill">
							<div class="d-flex h5">
								<div class="flex-fill"><Skeletor width="150" /></div>
								<div class="flex-fill text-nowrap text-end ps-2"><Skeletor width="70" /></div>
							</div>
						</div>
					</div>
				</div>
				<div class="list-group-item px-0">
					<div class="d-flex justify-content-between">
						<div>
							<Skeletor class="h5 progress-text mb-0 d-block" width="150" />
						</div>
						<div class="text-end">
							<Skeletor class="h5" width="100" />
						</div>
					</div>
					<div class="d-flex justify-content-between mt-2">
						<div>
							<Skeletor class="h5 progress-text mb-0 d-block" width="150" />
						</div>
						<div class="text-end">
							<Skeletor class="h5" width="100" />
						</div>
					</div>
					<div class="d-flex justify-content-between mt-2">
						<div>
							<Skeletor class="h4 progress-text mb-0 d-block" width="150" />
						</div>
						<div class="text-end">
							<Skeletor class="h4" width="100" />
						</div>
					</div>
				</div>
			</div>

			<div class="my-5">
				<Skeletor class="btn btn-neutral w-100" />
			</div>
		</div>
		<div v-if="order">
			<div class="d-flex justify-content-between mt-2">
				<div class="badge rounded-pill" :class="getStatusClass">{{ getStatusTitle }}</div>
				<div class="text-sm fw-bold">
					<span>Número: {{ order.number }}</span>
					<span class="ms-7">ID: {{ order.id }}</span>
				</div>
			</div>
			<div class="alert alert-danger p-2 mt-3 text-sm fw-bold text-center" role="alert" v-if="order.status == 'REJECTED' && order.rejected_reason_message">{{ order.rejected_reason_message }}</div>

			<div class="list-group list-group-flush mt-5">
				<div class="list-group-item d-flex align-items-center px-0 py-1 border-0">
					<div>
						<span class="d-block h6 fw-regular mb-0">Tienda</span>
					</div>
					<div class="ms-auto text-end">
						<router-link :to="{name: 'store.home', params: {slug: order.store.slug}}" class="fw-bold text-reset">{{ order.store.name }}</router-link>
					</div>
				</div>
				<div class="list-group-item d-flex align-items-center px-0 py-1 border-0" v-if="getDeliveredDateFormatted">
					<div>
						<span class="d-block h6 fw-regular mb-0">Fecha de entrega</span>
					</div>
					<div class="ms-auto text-end">
						<span class="fw-bold">{{ getDeliveredDateFormatted }}</span>
					</div>
				</div>
				<div class="list-group-item d-flex align-items-center px-0 py-1 border-0">
					<div>
						<span class="d-block h6 fw-regular mb-0">Hora de entrega</span>
					</div>
					<div class="ms-auto text-end">
						<span class="fw-bold">{{ getDeliveredTimeFormatted }}</span>
					</div>
				</div>
				<div class="list-group-item d-flex align-items-center px-0 py-1 border-0">
					<div>
						<span class="d-block h6 fw-regular mb-0">Tipo de entrega</span>
					</div>
					<div class="ms-auto text-end">
						<span class="fw-bold">{{ getType }}</span>
					</div>
				</div>
				<div class="list-group-item d-flex align-items-center px-0 py-1 border-0" v-if="['DELIVERY', 'LOCAL'].includes(order.type)">
					<div>
						<span class="d-block h6 fw-regular mb-0">Lugar</span>
					</div>
					<div class="ms-auto text-end">
						<span class="fw-bold">{{ getDeliveryTo }}</span>
					</div>
				</div>
				<div class="list-group-item d-flex align-items-center px-0 py-1 border-0">
					<div>
						<span class="d-block h6 fw-regular mb-0">Forma de pago</span>
					</div>
					<div class="ms-auto text-end">
						<span class="fw-bold" v-html="getPaymentMethod"></span>
					</div>
				</div>
				<div class="list-group-item d-flex align-items-center px-0 py-1 border-0" v-if="order.comments">
					<div>
						<span class="d-block h6 fw-regular mb-0">Aclaraciones</span>
					</div>
					<div class="ms-auto text-end">
						<span class="fw-bold">{{ order.comments }}</span>
					</div>
				</div>
			</div>

			<hr />

			<div class="mt-5">
				<div class="ls-tight fw-bolder h4">Detalle del pedido</div>
			</div>

			<div class="list-group list-group-flush mb-5">
				<orders-item v-for="(item, index) in order.items" v-bind:key="item.id" :item="item" :index="index" />
				<div class="list-group-item px-0">
					<div class="d-flex justify-content-between mb-2" v-if="order.subtotal != order.total">
						<div>
							<span class="h5 progress-text mb-0 d-block">Subtotal</span>
						</div>
						<div class="text-end">
							<span class="h5">{{ vueNumberFormat(order.subtotal, $personalizer.currency.value) }}</span>
						</div>
					</div>
					<div class="d-flex justify-content-between mb-2" v-if="order.discount > 0">
						<div>
							<span class="h5 text-danger progress-text mb-0 d-block">Descuento</span>
						</div>
						<div class="text-end">
							<span class="h5 text-danger">{{ vueNumberFormat(order.discount, $personalizer.currency.value) }}</span>
						</div>
					</div>
					<div class="d-flex justify-content-between mb-2" v-if="order.type == 'DELIVERY'">
						<div>
							<span class="h5 progress-text mb-0 d-block">Envío</span>
						</div>
						<div class="text-end">
							<span class="h5">{{ vueNumberFormat(order.delivery_amount, $personalizer.currency.value) }}</span>
						</div>
					</div>
					<div class="d-flex justify-content-between">
						<div>
							<h4 class="progress-text mb-0 d-block">Total</h4>
						</div>
						<div class="text-end">
							<span class="h4">{{ vueNumberFormat(order.total, $personalizer.currency.value) }}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="mb-8" v-if="order.store.phone">
				<a target="_blank" v-bind:href="'https://wa.me/'+order.store.phone" class="btn btn-neutral w-100">Contactar al comercio</a>
			</div>
		</div>
	</layouts-minimum>
</template>

<script>
	import { ref, onMounted, onBeforeUnmount } from 'vue';
	import { useRoute } from 'vue-router';
	import router from '@/router';
	import composableOrderDetail from '@/composables/orderDetail';
	import OrdersRepository from '@/repositories/OrdersRepository';
	import composableForm from '@/composables/form';

	export default {
		setup() {
			const { catchError } = composableForm();
			const { params: {id} } = useRoute();
			const order = ref(null);
			const timeout = ref(null);
			const { getStatusTitle, getStatusClass, getDeliveredDateFormatted, getDeliveredTimeFormatted, getType, getDeliveryTo, getPaymentMethod, dataRefresh } = composableOrderDetail(order);

			const getOrder = async () => {
				OrdersRepository.get(id).then(onSuccess).catch((e) => {
					catchError(e);
					router.push({name: 'order.index'});
				});
			}

			const onSuccess = (response) => {
				setData(response.data);
				if(dataRefresh.value) timeout.value = setTimeout(getOrder, (dataRefresh.value * 1000));
			}

			const setData = (detail) => {
				var items_assoc = [];

				detail.items = [];

				detail.order_detail.forEach(function(item) {
					if(item.ref == null) {
						items_assoc[item.id] = item;
						items_assoc[item.id].additionals = [];
					}else{
						items_assoc[item.ref].additionals.push(item);
					}
				});

				items_assoc.forEach(function(item) { detail.items.push(item); });
				order.value = detail;
			}

			onMounted(getOrder);

			onBeforeUnmount(() => {
				if(timeout.value) clearTimeout(timeout.value);
			});

			return { order, getStatusTitle, getStatusClass, getDeliveredDateFormatted, getDeliveredTimeFormatted, getType, getDeliveryTo, getPaymentMethod };
		}
	}
</script>